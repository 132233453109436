export const countryOptions = [
  { label: "Global", value: "37i9dQZEVXbMDoHDwVN2tF" },
  { label: "Australia", value: "37i9dQZEVXbJPcfkRz0wJ0" },
  { label: "Austria", value: "37i9dQZEVXbKNHh6NIXu36" },
  { label: "Belgium", value: "37i9dQZEVXbJNSeeHswcKB" },
  { label: "Brazil", value: "37i9dQZEVXbMXbN3EUUhlg" },
  { label: "Canada", value: "37i9dQZEVXbKj23U1GF4IR" },
  { label: "Denmark", value: "37i9dQZEVXbL3J0k32lWnN" },
  { label: "Egypt", value: "37i9dQZEVXbLn7RQmT5Xv2" },
  { label: "Estonia", value: "37i9dQZEVXbLesry2Qw2xS" },
  { label: "Finland", value: "37i9dQZEVXbMxcczTSoGwZ" },
  { label: "France", value: "37i9dQZEVXbIPWwFssbupI" },
  { label: "Germany", value: "37i9dQZEVXbJiZcmkrIHGU" },
  { label: "Hong Kong", value: "37i9dQZEVXbLwpL8TjsxOG" },
  { label: "Iceland", value: "37i9dQZEVXbKMzVsSGQ49S" },
  { label: "India", value: "37i9dQZEVXbLZ52XmnySJg" },
  { label: "Indonesia", value: "37i9dQZEVXbObFQZ3JLcXt" },
  { label: "Ireland", value: "37i9dQZEVXbKM896FDX8L1" },
  { label: "Italy", value: "37i9dQZEVXbIQnj7RRhdSX" },
  { label: "Japan", value: "37i9dQZEVXbKXQ4mDTEBXq" },
  { label: "Latvia", value: "37i9dQZEVXbJWuzDrTxbKS" },
  { label: "Lithuania", value: "37i9dQZEVXbMx56Rdq5lwc" },
  { label: "Luxembourg", value: "37i9dQZEVXbKGcyg6TFGx6" },
  { label: "Malaysia", value: "37i9dQZEVXbJlfUljuZExa" },
  { label: "Mexico", value: "37i9dQZEVXbO3qyFxbkOE1" },
  { label: "Netherlands", value: "37i9dQZEVXbKCF6dqVpDkS" },
  { label: "New Zealand", value: "37i9dQZEVXbM8SIrkERIYl" },
  { label: "Nigeria", value: "6TxKEKTt71P0a2l4MiiPMa" },
  { label: "Norway", value: "37i9dQZEVXbJvfa0Yxg7E7" },
  { label: "Pakistan", value: "37i9dQZEVXbJkgIdfsJyTw" },
  { label: "Philippines", value: "37i9dQZEVXbNBz9cRCSFkY" },
  { label: "Poland", value: "37i9dQZEVXbN6itCcaL3Tt" },
  { label: "Portugal", value: "37i9dQZEVXbKyJS56d1pgi" },
  { label: "Singapore", value: "37i9dQZEVXbK4gjvS1FjPY" },
  { label: "Spain", value: "37i9dQZEVXbNFJfN1Vw8d9" },
  { label: "Sweden", value: "37i9dQZEVXbLoATJ81JYXz" },
  { label: "Switzerland", value: "37i9dQZEVXbJiyhoAPEfMK" },
  { label: "Taiwan", value: "37i9dQZEVXbMnZEatlMSiu" },
  { label: "Turkey", value: "37i9dQZEVXbIVYVBNw9D5K" },
  { label: "United Kingdom", value: "37i9dQZEVXbLnolsZ8PSNw" },
  { label: "United States of America", value: "37i9dQZEVXbLRQDuF5jeBp" },
  { label: "Vietnam", value: "37i9dQZEVXbLdGSmz6xilI" },
];

export const decadeOptions = [
  { label: "1950s", value: "37i9dQZF1DWSV3Tk4GO2fq" },
  { label: "1960s", value: "37i9dQZF1DXaKIA8E7WcJj" },
  { label: "1970s", value: "37i9dQZF1DWTJ7xPn4vNaz" },
  { label: "1980s", value: "37i9dQZF1DX4UtSsGT1Sbe" },
  { label: "1990s", value: "37i9dQZF1DXbTxeAdrVG2l" },
  { label: "2000s", value: "37i9dQZF1DX4o1oenSJRJd" },
];

export const HOST_URL = "https://heardle-unlimited.onrender.com";
export const SERVER_URL = "https://heardle-unlimited-service.onrender.com";
